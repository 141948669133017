<template lang="pug">
  transition(name="slide-fade")
    div
      loading(:active.sync="busy", :is-full-page="true")
      .widget
        .widget-header
          h1.title Publishers
          i.la.la-2x.la-filter.d-flex.align-items-center.mr-2.mobile(@click="areFiltersOpened = !areFiltersOpened")
        .widget-body
          filters(@submit="loadData()", :default-filters="defaultFilters", :current-filters="filters", :page-name="'publishersList'", :is-opened="areFiltersOpened", @close-filters="areFiltersOpened = false")
            .form-row-main
              .form-group
                label Search
                input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword")
              .form-group
                label Status
                select.form-control(v-model="filters.status")
                  option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
              .form-group
                label Media Type
                select.form-control(v-model="filters.media_type")
                  option(value="") All
                  option(v-for="o in mediaTypeOptions", :value="o.v") {{ o.t }}
              .form-group.no-label
                b-button-group.submit-filters-button
                  b-dropdown(split-button-type="submit", variant="primary", right, split, text="Go")
                    b-dropdown-item(@click="loadData(1)")
                      i.la.la-download
                      | Export

          .form-row-end
            .form-group
              label &nbsp;
              router-link.btn.btn-success(:to="{name:'publisher-add'}", v-if="USER.permissions['publishers::EDIT']")
                i.la.la-plus
                | Add


          p.clearfix &nbsp;

          .ov-table-wrapper
            table.table.table-bordered
              thead
                tr
                  th ID
                  th Active
                  th Type
                  th Name
                  th(width="1%") API Key
                  th Joined
                  th Actions
              tbody
                tr(v-if="records.length===0")
                  td(colspan="6") No matching records were found
                tr(v-for="r in records", v-bind:key="r.id")
                  td {{ r.id }}
                  td
                    toggle-button(v-model="r._status", :font-size="12", @change="updateField(r, 'status')", :disabled="!!r.reserved")
                  td
                    .badge.badge-pill.badge-info(v-if="r.media_type==='rewarded'") REWARDED
                    .badge.badge-pill.badge-success(v-if="r.media_type==='dsp'") DSP
                    .badge.badge-pill.badge-warning(v-if="r.media_type==='media_buying'") MEDIA BUYING
                  td.edit-inline
                    quick-edit(v-if="USER.permissions.admin_only", field="name", :r="r", @on-save="updateField", :wrapped="true")
                    span(v-if="!USER.permissions.admin_only") {{ r.name }}
                  td
                    .input-group.input-group-sm.input-api-key()
                      input.form-control(:value="r.api_key", readonly="readonly")
                      .input-group-append(v-if="!r.reserved && resetAPIKey || (!r.api_key && newAPIKey)")
                        a.btn.btn-outline-secondary(href="javascript:void(0);", @click="refreshToken(r)")
                          i.la.la-refresh
                  td {{ r.created_at }}
                  td.actions
                    .action-buttons(v-if="!r.reserved")
                      router-link.btn.btn-sm.btn-secondary.mr-1(:to="{name:'publisher-edit', params: {id:r.id}}", v-b-tooltip.hover.bottom, title="Edit")
                        i.la.la-pencil
                      router-link.btn.btn-sm.btn-primary.mr-1(:to="{name:'publisher-api', params: {id:r.id}}", v-b-tooltip.hover.bottom, title="API offers")
                        i.la.la-code
                      b-button.btn.btn-sm.btn-primary(:disabled="!r.api_key", type="button", v-clipboard:copy="r.offerwall", v-clipboard:success="onCopySuccess", v-clipboard:error="onCopyError",
                        v-b-tooltip.hover.bottom, title="API Publisher Link")
                        i.la.la-copy
                      a.btn.btn-sm.btn-info.mr-1(v-if="USER.permissions.admin_only", v-b-tooltip.hover.bottom, :title="`Login As ${r.name}`", @click="loginAsPublisher(r)")
                        i.la.la-sign-in-alt
                      //a.btn.btn-sm.btn-secondary.mr-1(href="javascript:void(0);", v-if="USER.permissions.admin_only")
                        i.la.la-trash
            paginate(:paginator="paginate", @update-page-size="loadData")


</template>
<script>
import Vue from 'vue';

export default {
  name: 'PublisherList',
  computed: {
    resetAPIKey() {
      return this.USER.permissions.admin_only || this.USER.permissions['publishers::RESET_API_KEY'];
    },
    newAPIKey() {
      return this.USER.permissions.admin_only || this.USER.permissions['publishers::NEW_API_KEY'];
    },
    USER() {
      return this.$store.state.user;
    },
    mediaTypeOptions() {
      let options = [
        { v: 'dsp', t: 'DSP' },
        { v: 'rewarded', t: 'REWARDED' },
      ];
      if (this.$store.state.user && this.$store.state.user.config.mediaBuyingSync) {
        options.push({ v: 'media_buying', t: 'MEDIA BUYING' });
      }
      return options;
    },
  },
  data() {
    const defaultFilters = {
      keyword: '',
      media_type: '',
      status: 1,
    };
    return {
      areFiltersOpened: false,
      busy: false,
      paginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 50,
        onPageChange: () => {
          this.loadData();
        },
      },
      defaultFilters,
      filters: { ...defaultFilters },
      statusOptions: [
        { v: undefined, t: 'All' },
        { v: 0, t: 'Inactive' },
        { v: 1, t: 'Active Only' },
      ],
      records: [],
    };
  },
  methods: {
    onCopySuccess() {
      this.$ovNotify.success('Copied to clipboard');
    },
    onCopyError() {
      this.$ovNotify.error('Could not copy text');
    },
    async updateField(r, field) {
      let v = r['_' + field];
      let data = {
        id: r.id,
        key: field,
        val: v,
      };
      try {
        let resp = await this.$ovReq.post('publisher/updateField', data);
        r['_edit_' + field] = false;
        r[field] = resp.entity[field];
        this.$ovNotify.success('Record has been updated');
      } catch (e) {
        this.$ovNotify.error(e);
      }
    },
    async refreshToken(r) {
      let confirmed = await this.$bvModal.msgBoxConfirm('Generate API key?');
      if (confirmed) {
        this.busy = true;

        try {
          let resp = await this.$ovReq.post('publisher/generateAPIKey', { id: r.id });
          r.api_key = resp.api_key;
          r.offerwall = resp.offerwall;
          this.$ovNotify.success('API Key has been generated');
        } catch (e) {
          this.$ovNotify.error(e);
        }
        this.busy = false;
      }
    },
    async loadData(doExport) {
      this.busy = true;
      let params = {
        export: doExport ? doExport : 0,
        page: this.paginate.page,
        page_size: this.paginate.limit,
        keyword: this.filters.keyword,
        media_type: this.filters.media_type,
      };
      if (this.filters.status === 0) {
        params.status = 0;
      } else if (this.filters.status === 1) {
        params.status = 1;
      }
      try {
        let resp = await this.$ovReq.get('publisher/getList', { params });
        if (doExport === 1) {
          window.open(resp.path);
        } else {
          this.records = resp.records;
          this.records.forEach((r) => {
            r.status = !!r.status;
            Vue.set(r, '_edit_name', false);
            Vue.set(r, '_name', r.name);
            Vue.set(r, '_status', r.status);
          });
          this.paginate.total = resp.total;
          this.paginate.numPages = Math.max(
            Math.ceil(this.paginate.total / this.paginate.limit),
            1,
          );
        }
      } catch (e) {
        this.$ovNotify.error(e);
      }
      this.busy = false;
    },

    async loginAsPublisher(publisher) {
      if (this.busy) {
        return;
      }
      this.busy = true;
      try {
        let resp = await this.$ovReq.post('user/loginAsPublisher', { publisher_id: publisher.id });
        if (resp && resp.url) {
          window.open(resp.url, '_blank').focus();
        }
      } catch (e) {
        this.$ovNotify.error(e);
      }
      this.busy = false;
    },
  },
  created() {
    this.loadData();
  },
};
</script>
